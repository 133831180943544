import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import './successScreen.css';

const SuccessScreen = () => { // Mantido se for necessário para outros props ou lógica futura

    useEffect(() => {
        const timer = setTimeout(() => {
            // Recarrega a página após 3 segundos
            window.location.reload();
        }, 3000);

        return () => clearTimeout(timer);
    }, []);

    return (
        <div className="success-screen">
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                height="100%"
                bgcolor="rgba(0, 0, 0, 0.5)" // Fundo transparente
                position="fixed"
                top={0}
                left={0}
                right={0}
                bottom={0}
                zIndex={1300}
            >
                <CheckCircleIcon style={{ fontSize: 100, color: 'green' }} />
                <Typography variant="h4" style={{ color: 'white', marginTop: 20 }}>
                    Sucesso!
                </Typography>
            </Box>
        </div>
    );
};

export default SuccessScreen;
