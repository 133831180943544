//import React from 'react';
//import { Box, List, ListItem, ListItemText, Typography } from '@mui/material';
import './Sidebar.css';

const Sidebar = () => {
  
};

export default Sidebar;


/*const Sidebar = () => {
  return (
    <Box className="sidebar-container">
      <List>
        <ListItem className="sidebar-item">
          <ListItemText primary="Bot" />
        </ListItem>
        <ListItem className="sidebar-item">
          <ListItemText
            primary={
              <Typography style={{ color: 'blue' }}>
                Disparador
              </Typography>
            }
          />
        </ListItem>
        <ListItem className="sidebar-item">
          <ListItemText
            primary={
              <Box>
                <Typography component="span">Fluxo de automação</Typography>
                <Typography component="span" className="coming-soon">Em breve!</Typography>
              </Box>
            }
          />
        </ListItem>
      </List>
    </Box>
  );
};*/