import React from 'react';
import { AppBar, Toolbar, Typography } from '@mui/material';
import './headerStyle.css';

const Header = () => {
  return (
    <AppBar position="static" className="custom-appbar">
      <Toolbar>
        <Typography variant="h6" noWrap>
          Bem-vindo
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
