import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import myImage from '../assets/images/my-image.jpeg';
import './home.css';

const Home = () => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('disparador');
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        textAlign: 'center',
      }}
    >
      <img src={myImage} alt="Description" className="image" /> {/* Aplica a classe "image" à imagem */}
      <Typography variant="h4" gutterBottom>
        Criar automações para seus atendimentos
      </Typography>
      <Typography variant="body1" gutterBottom>
        Com os Fluxos de Automação, você consegue montar estratégias de atendimento de uma forma simples e transparente!
      </Typography>
      <Button
        variant="contained"
        color="primary"
        style={{ marginTop: '20px' }}
        onClick={handleButtonClick}
      >
        Criar campanha
      </Button>
    </Box>
  );
};

export default Home;
