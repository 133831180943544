import React from 'react';
import { BrowserRouter as Router, Routes, Route, useParams } from 'react-router-dom';
import { Box, CssBaseline } from '@mui/material';
import Sidebar from './components/Sidebar';
import Home from './components/Home';
import DisparadorWrapper from './components/Disparador';
import BrokerModalWrapper from './components/BrokerModal';
import WabaModalWrapper from './components/WabaModal';
import Header from './components/Header';
import InstaModal from './components/instagram/instaModal';

function App() {
  return (
    <Router>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Sidebar />
        <Box component="main" sx={{ flexGrow: 1, bgcolor: '#f9f9f9', height: '100vh' }}>
          <Routes>
            <Route path=":acontID/:token" element={<HomeWithHeader />} />
            <Route path=":acontID/:token/disparador" element={<DisparadorWithModals />} />
            <Route path=":acontID/:token/instagram" element={<InstaModalWithHeader />} /> {/* Adding new Route */}
          </Routes>
        </Box>
      </Box>
    </Router>
  );
}

const HomeWithHeader = () => {
  const { acontID, token } = useParams();

  return (
    <>
      <Header acontID={acontID} token={token} />
      <Home />
    </>
  );
};

const DisparadorWithModals = () => {
  const { acontID, token } = useParams();

  return (
    <>
      <Header acontID={acontID} token={token} />
      <DisparadorWrapper acontID={acontID} token={token} />
      <BrokerModalWrapper acontID={acontID} token={token} />
      <WabaModalWrapper acontID={acontID} token={token} />
    </>
  );
};

// Adding InstaModal with Header
const InstaModalWithHeader = () => {
  const { acontID, token } = useParams();

  return (
    <>
      <Header acontID={acontID} token={token} />
      <InstaModal acontID={acontID} token={token} />
    </>
  );
};

export default App;
